@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Quicksand:300,300i,400,400i,600,600i,700,700i,800,800i&subset=latin-ext);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@charset "UTF-8";
body {
  font-size: 12pt;
  color: #606060;
}

* {
  font-family: "Quicksand", sans-serif !important;
}

.mr-1 {
  margin-right: 1em;
}

.mb-0 {
  margin-bottom: 0;
}

.dropdown-toggle-container {
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  border: none;
}

.ul-dropdown {
  margin: 0px;
  list-style: none;
  font-weight: bold;
  font-size: 14px;
}

.dropdown-menu-drawer {
  font-weight: 400;
  border-radius: 8px;
  width: 100%;
  text-align: left;
  background: #863bff;
  box-shadow: none;
  font-size: 1.1rem;
  padding: 9px;
  border-radius: 0px 0px 8px 8px;
}

.MuiSvgIcon-root {
  font-size: 34px;
}

.dropdown-item:active {
  background-color: transparent !important;
}

.btn {
  font-size: 15px !important;
  text-align: left;
}

.menu {
  font-weight: bold;
  font-size: 14px !important;
  text-align: left;
  display: grid;
  text-align: center;
  margin-left: 30px;
  margin-top: 0px;
  color: white;
  padding: 0px;
  border-radius: 5px;
  z-index: 100;
  justify-items: start;
}

.menu-children {
  text-align: left;
  cursor: pointer;
  padding: 8px;
  font-size: 12px !important;
}
.menu-children.disabled {
  opacity: 50%;
  cursor: not-allowed;
}

.disabled {
  opacity: 50%;
  font-size: 12px !important;
  cursor: not-allowed;
}

.menu-children:not(:last-child) {
  font-size: 12px !important;
  border-bottom: 1px solid transparent;
}

.table-avalia-default {
  border: 1px solid #bcbcbc;
  border-radius: 11px;
}
@media (max-width: 769px) {
  .table-avalia-default .-pagination .-pageSizeOptions {
    position: static !important;
    position: initial !important;
  }
}
@media (max-width: 490px) {
  .table-avalia-default .-center {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.table-avalia-default .-header {
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15);
}
.table-avalia-default .rt-thead .rt-th {
  color: black;
  font-weight: 600;
  text-align: left;
  border-color: #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  overflow: visible !important;
  white-space: normal !important;
  align-items: center;
  background-color: #d9d9d9;
}
.table-avalia-default .rt-thead .-sort-desc:after {
  content: "▼";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-avalia-default .rt-thead .-sort-asc:before {
  content: "▲";
  position: absolute;
  top: 8px;
  right: 10px;
}
.table-avalia-default .rt-thead .rt-th.-sort-desc {
  box-shadow: none;
  font-weight: 600;
}
.table-avalia-default .rt-tbody {
  border: none;
  overflow: visible !important;
  overflow: initial !important;
}
.table-avalia-default .rt-tbody .rt-tr {
  align-items: center;
}
.table-avalia-default .rt-tbody .-odd {
  background: #ededed;
}
.table-avalia-default .rt-tbody .-even {
  background: #ffffff;
}
.table-avalia-default .rt-tbody .rt-td {
  width: auto;
  color: #000;
  padding: 10px 10px !important;
  border-color: #e3e3e3;
  overflow: visible;
  white-space: normal;
  align-items: center;
  justify-content: space-between;
}
.table-avalia-default .rt-tbody .rt-td p {
  margin: 0;
}
.table-avalia-default .rt-tbody .rt-td .column-label {
  display: none;
}
.table-avalia-default .rt-tbody .rt-td .table-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-avalia-default .rt-tbody .rt-td .container-check-custom {
  display: inline;
}

@media all and (max-width: 768px) {
  .table-responsive {
    padding: none;
  }
  .table-responsive .rt-thead .rt-tr {
    display: none;
  }
  .table-responsive .rt-tbody {
    font-size: 13px;
    min-width: inherit !important;
  }
  .table-responsive .rt-tbody .rt-tr {
    display: block;
  }
  .table-responsive .rt-tbody .rt-td {
    max-width: 100% !important;
    width: 100% !important;
    border: none;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-height: 29px;
  }
  .table-responsive .rt-tbody .rt-td .responsive-row {
    text-align: left;
    padding-left: 55% !important;
  }
  .table-responsive .rt-tbody .rt-td .table-buttons {
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .table-responsive .rt-tbody .rt-td .column-label {
    width: 50%;
    display: block;
    position: absolute;
    left: 1rem;
    font-weight: bold;
  }
}
.expanded-rows .rt-tbody .rt-td {
  padding: 1.5rem 1rem !important;
}

.tabela-horario .rt-tbody .rt-tr {
  font-size: 12px;
  color: #f5f5f5;
}

.-btn {
  width: 60% !important;
}

.-next .-btn {
  float: right;
}

.break-line {
  overflow: visible;
  white-space: normal !important;
}

.table-student-diagnosis-default {
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
}
@media (max-width: 769px) {
  .table-student-diagnosis-default .-pagination .-pageSizeOptions {
    position: static !important;
    position: initial !important;
  }
}
@media (max-width: 490px) {
  .table-student-diagnosis-default .-center {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
.table-student-diagnosis-default .-header {
  border-radius: 10px !important;
  box-shadow: 5px 7px 8px 1px rgba(0, 0, 0, 0.15) !important;
}
.table-student-diagnosis-default .rt-thead .-sort-desc:after {
  content: "▼" !important;
  position: absolute !important;
  top: 8px !important;
  right: 10px !important;
}
.table-student-diagnosis-default .rt-thead .-sort-asc:before {
  content: "▲" !important;
  position: absolute !important;
  top: 8px !important;
  right: 10px !important;
}
.table-student-diagnosis-default .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important;
  font-weight: 600 !important;
}
.table-student-diagnosis-default .rt-tbody {
  border: none !important;
  overflow: visible !important;
  overflow: initial !important;
}
.table-student-diagnosis-default .rt-tbody .rt-tr {
  border-radius: 10px !important;
  align-items: center !important;
}
.table-student-diagnosis-default .rt-tbody .-odd {
  background: #d9d9d9 !important;
}
.table-student-diagnosis-default .rt-tbody .-even {
  background: #ededed !important;
}
.table-student-diagnosis-default .rt-tbody .rt-td {
  color: #000 !important;
  padding: 10px 10px !important;
  border-color: #ccc !important;
  overflow: visible !important;
  white-space: normal !important;
  display: flex !important;
  align-items: center !important;
}
.table-student-diagnosis-default .rt-tbody .rt-td p {
  margin: 0 !important;
}
.table-student-diagnosis-default .rt-tbody .rt-td .column-label {
  display: none !important;
}
.table-student-diagnosis-default .rt-tbody .rt-td .table-buttons {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.table-student-diagnosis-default .rt-tbody .rt-td .container-check-custom {
  display: inline !important;
}

@media all and (max-width: 768px) {
  .table-responsive {
    padding: none !important;
  }
  .table-responsive .rt-thead .rt-tr {
    display: none !important;
  }
  .table-responsive .rt-tbody {
    font-size: 13px !important;
    min-width: inherit !important;
  }
  .table-responsive .rt-tbody .rt-tr {
    display: block !important;
  }
  .table-responsive .rt-tbody .rt-td {
    max-width: 100% !important;
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid #eaeaea !important;
    position: relative !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    min-height: 29px !important;
  }
  .table-responsive .rt-tbody .rt-td .responsive-row {
    text-align: left !important;
    padding-left: 55% !important;
  }
  .table-responsive .rt-tbody .rt-td .table-buttons {
    padding-bottom: 0.5rem !important;
    display: flex !important;
    align-items: center !important;
  }
  .table-responsive .rt-tbody .rt-td .column-label {
    width: 50% !important;
    display: block !important;
    position: absolute !important;
    left: 1rem !important;
    font-weight: bold !important;
  }
}
.expanded-rows .rt-tbody .rt-td {
  padding: 1.5rem 1rem !important;
}

.tabela-horario .rt-tbody .rt-tr {
  font-size: 12px !important;
  color: #f5f5f5 !important;
}

.-btn {
  width: 60% !important;
}

.-next .-btn {
  float: right !important;
}

.break-line {
  overflow: visible !important;
  white-space: normal !important;
}

.edital-table th {
  color: #fff !important;
  background: #f5f5f5 !important;
  font-weight: 200 !important;
}

.edital-table td {
  background: #fff !important;
}

.edital-table .table-name {
  font-weight: bold !important;
}

.edital-table .table-status {
  font-weight: bold !important;
}

.edital-table .table-status.aprovado {
  color: #28a745 !important;
}

.edital-table .table-status.reprovado {
  color: #aa2e2e !important;
}

.edital-table .table-status.analise {
  color: #f5c246 !important;
}

tr.positive td {
  background: #c1ffc8 !important;
}

tr.negative td {
  background: #ffb2b2 !important;
}

.status-inf {
  display: flex !important;
}

.status-inf p:nth-child(1) {
  padding-right: 10px !important;
}

.status-inf p:nth-child(2) {
  padding-left: 10px !important;
  border-left: 1px solid #e3e3e3;
}

.status-inf p:nth-child(1):before {
  content: "" !important;
  font-family: "FontAwesome" !important;
  color: #c1ffc8 !important;
  padding-right: 5px !important;
}

.status-inf p:nth-child(2):before {
  content: "" !important;
  font-family: "FontAwesome" !important;
  padding-right: 5px !important;
  color: #ffb2b2 !important;
}

.tabs-nav {
  width: 100% !important;
  display: inline-flex !important;
  text-align: left !important;
  list-style: none !important;
  justify-content: flex-end !important;
  flex: 1 1 !important;
}
.tabs-nav li {
  padding: 10px 10px !important;
}
.tabs-nav button {
  color: rgba(17, 17, 17, 0.6) !important;
}
.tabs-nav button.tab--active {
  border-bottom: 2px solid #f5f5f5 !important;
  font-weight: 600 !important;
  color: rgba(17, 17, 17, 0.9) !important;
}
.tabs-nav button:not(.tab--active) {
  border-bottom: 1px solid transparent !important;
  color: #383838 !important;
}

@media screen and (max-width: 768px) {
  .tabs-nav {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
    text-align: center !important;
    flex-wrap: wrap !important;
    justify-content: initial !important;
  }
  .tabs-nav li {
    padding: 15px 6px !important;
    width: 155px !important;
  }
  .tabs-nav button:not(.tab--active) {
    border-bottom: 1px solid rgba(17, 17, 17, 0.1) !important;
  }
}
.table-default {
  font-size: 12px !important;
  border: none !important;
}
.table-default .rt-table {
  background: white !important;
}
.table-default .rt-table .container-check-custom:first-child {
  margin-top: 9px !important;
}
.table-default .rt-table .container-check-custom input[type=checkbox] {
  top: 0 !important;
  left: 14px !important;
}
.table-default .rt-table .checkmark {
  top: 0 !important;
  left: 14px !important;
}
.table-default .rt-table .rt-thead,
.table-default .rt-table thead {
  border: 1px solid #e0e0e0 !important;
  border-bottom-width: 2px !important;
  box-shadow: none !important;
  font-size: 13px !important;
}
.table-default .rt-table .rt-thead .rt-th,
.table-default .rt-table .rt-thead th,
.table-default .rt-table thead .rt-th,
.table-default .rt-table thead th {
  border-right: none !important;
  color: #00617b !important;
  border-right: 1px solid #e0e0e0 !important;
  font-weight: 600 !important;
  text-align: left !important;
  padding: 5px !important;
  display: flex !important;
  align-items: center !important;
}
.table-default .rt-table .rt-thead .rt-th:last-child,
.table-default .rt-table .rt-thead th:last-child,
.table-default .rt-table thead .rt-th:last-child,
.table-default .rt-table thead th:last-child {
  border-right: none !important;
}
.table-default .rt-table .rt-thead .-sort-asc,
.table-default .rt-table thead .-sort-asc {
  box-shadow: inset 0 3px 0 0 #00617b !important;
}
.table-default .rt-table .rt-thead .-sort-desc,
.table-default .rt-table thead .-sort-desc {
  box-shadow: inset 0 -3px 0 0 #00617b !important;
}
.table-default .rt-table .rt-thead:nth-child(2),
.table-default .rt-table thead:nth-child(2) {
  border: 1px solid #d5d5d5 !important;
  border-right: none !important;
  border-top: none !important;
}
.table-default .rt-table .rt-thead:nth-child(2) .rt-th,
.table-default .rt-table thead:nth-child(2) .rt-th {
  border-right: 1px solid #d5d5d5 !important;
}
.table-default .rt-table .rt-tbody,
.table-default .rt-table tbody {
  border: 1px solid #d5d5d5 !important;
  border-top: none !important;
  background: white !important;
  overflow: visible !important;
  overflow: initial !important;
}
.table-default .rt-table .rt-tbody .rt-tr-group,
.table-default .rt-table tbody .rt-tr-group {
  border-color: #d5d5d5 !important;
}
.table-default .rt-table .rt-tbody .rt-tr-group .rt-td,
.table-default .rt-table .rt-tbody .rt-tr-group td,
.table-default .rt-table tbody .rt-tr-group .rt-td,
.table-default .rt-table tbody .rt-tr-group td {
  border-color: #d5d5d5 !important;
  padding: 10px 5px !important;
  display: flex !important;
  align-items: center !important;
  color: gray !important;
  white-space: normal !important;
}
.table-default .rt-table .rt-tbody .rt-tr-group .rt-td:last-child,
.table-default .rt-table tbody .rt-tr-group .rt-td:last-child {
  overflow: visible !important;
  overflow: initial !important;
}
.table-default .pagination-bottom .-pagination {
  align-items: center !important;
  justify-content: flex-end !important;
  position: relative !important;
  padding: 10px 0 !important;
}
.table-default .pagination-bottom .-pagination .-previous {
  margin-right: 15px !important;
  flex: 0 1 !important;
}
.table-default .pagination-bottom .-pagination .-previous button {
  padding: 7px 20px !important;
  text-transform: uppercase !important;
}
.table-default .pagination-bottom .-pagination .-center {
  position: absolute !important;
  left: 0 !important;
  color: #9c9c9c !important;
}
.table-default .pagination-bottom .-pagination .-center .-pageJump {
  color: #9c9c9c !important;
}
.table-default .pagination-bottom .-pagination .-next {
  flex: 0 1 !important;
  margin-right: 15px !important;
}
.table-default .pagination-bottom .-pagination .-next button {
  padding: 7px 20px !important;
  text-transform: uppercase !important;
}

div.tooltip {
  position: absolute;
  text-align: center;
  padding: 0.2rem;
  background: #313639;
  color: #f9f9f9;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  font-size: 0.7rem;
}

svg {
  display: block;
  width: 100%;
  background: "white";
  overflow: visible;
}

svg rect.selection {
  stroke: none;
}

.skill-text {
  margin-left: 1rem;
  font-weight: 500;
  text-align: justify;
}

.axis__text {
  padding: 0.8rem;
  border-radius: 8px;
  background: #fff;
  display: flex;
}

.test {
  width: 100%;
  height: 200px !important;
  padding: 1.2rem 1rem 2rem 1.3rem;
  height: auto;
  overflow: visible;
}

.test rect.selection {
  stroke: none;
}

.teaching__field {
  padding: 1rem;
  font-family: "Open Sans", sans-serif;
  border-radius: 8px;
  margin-top: 0.5rem;
}

.matriz {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #3c4583;
}

.school__field {
  background: #d9d9d9;
  padding: 1rem;
  font-family: "Open Sans", sans-serif;
  border-radius: 8px;
  margin-top: 1rem;
}

.table-proficiency {
  border-radius: 15px 15px 0 0;
  margin-top: 1rem;
  padding: 1rem;
  background: #d9d9d9;
  text-align: center;
  width: 100%;
}

span {
  text-align: justify;
}

.proeficiency__infos {
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
}

.axis__infos {
  padding: 1.2rem;
  border-bottom: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
  border-left: 2px solid #e2e2e2;
  width: 100%;
  border-radius: 10px 0 0 10px;
  background: #f1f1f1;
}

.graphic {
  margin-left: 0rem;
  width: 55%;
  height: auto;
  padding: 1.2rem;
  border-bottom: 2px solid #e2e2e2;
  border-top: 2px solid #e2e2e2;
  border-right: 2px solid #e2e2e2;
  border-radius: 0 10px 10px 0;
  background: #ffff;
}

.box__mt {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.infos__school {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

h2 {
  font-size: 1rem;
  font-weight: bold;
}

.example-hability {
  font-size: 0.9rem;
}

.box {
  padding: 1.5rem;
}

hr {
  width: auto;
  background: #d9d9d9;
  border: none;
}

.pro-hr {
  margin-top: 0.1rem;
}

.school__material {
  margin-top: 0.9rem;
  font-weight: bold;
  color: #3c4583;
  text-align: center;
}

.school__material > span {
  font-size: 1.3rem;
}

.habilidade {
  display: flex;
  height: 73%;
  margin-top: 1rem;
  padding: 0.8rem;
  border-radius: 8px;
  background: #fff;
}

@media screen and (max-width: 769px) {
  .proeficiency__infos {
    display: flex;
    flex-direction: column;
  }

  .axis__infos {
    padding: 1.2rem;
    border-right: 2px solid #e2e2e2;
    border-bottom: none;
    border-top: 2px solid #e2e2e2;
    border-left: 2px solid #e2e2e2;
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: #f5f5f5;
  }

  .graphic {
    margin: 0 !important;
    margin-top: 0.5rem !important;
    width: 100%;
    height: auto;
    padding: 1.2rem;
    border-left: 2px solid #e2e2e2;
    border-top: none;
    border-right: 2px solid #e2e2e2;
    border-radius: 0 0 10px 10px;
    background: #f5f5f5;
  }

  .skill-text {
    margin-left: 1rem;
    font-weight: 500;
    text-align: justify;
  }
}

/*# sourceMappingURL=main.css.map */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

